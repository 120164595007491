import React from "react"
import Loadable from "@loadable/component"

import Frame from "~/ui/layout/frame"
import Button from "~/ui/elements/button/link"

function NotFoundPage() {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  return (
    <Frame>
      <Analytics />
      <div className="bg-yellow-200 py-32 text-center">
        <h1 className="font-bold text-[100px] text-font-1 leading-none mb-4">
          404
        </h1>
        <p className="font-bold text-5xl text-font-1 mb-3">Page not found</p>
        <p className="text-2xl text-font-1 mb-8">
          Sorry, we can't find the page you're looking for
        </p>
        <Button type="primary" to="/">
          Back to home
        </Button>
      </div>
    </Frame>
  )
}

export default NotFoundPage
